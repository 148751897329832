.page-container.github {
  @include flexbox(flex-start, flex-start, row);
  width: 100%;
  padding: 0 20px;
  padding-top: 20px;
  margin-bottom: 200px;
  position: relative;
  gap: 10px;

  @import "auth";

  @media screen and (max-width: 1560px) {
    flex-direction: column;
  }

  .my-profile {
    @include transition-basic;
    @include flexbox(center, flex-start, column);
    min-width: 300px;
    width: 30%;
    background: rgb(35, 35, 35);
    border-radius: 10px;
    position: sticky;
    top: 20px;
    @include fit-content-height;
    padding: 20px;
    @include overflow;
    gap: 10px;
    @media screen and (max-width: 1560px) {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
    }
    .username {
      margin-bottom: 40px;
      a {
        font-size: 2em;
      }
    }
    .avatar {
      width: 100%;
      max-width: 100px;
      border-radius: 20px;
      position: absolute;
      right: -5px;
      top: -5px;
      outline: 10px solid rgb(35, 35, 35);
      @include transition-basic;

      @media screen and (max-width: 1560px) {
        right: 0;
        top: 0;
      }
      @media screen and (max-width: 520px) {
        display: none;
      }
    }
    .info {
      width: 100%;
      @include flexbox(center, flex-start, column);
      gap: 20px;
      .name {
        font-size: 1.5em;
        color: white;
      }
      .hireable {
        font-size: 1.2em;
        color: $primary-success-green;
      }
      .bio {
        width: 100%;
        margin-top: 10px;
        background: rgb(30, 30, 30);
        padding: 10px 20px;
        padding-top: 15px;
        border-radius: 5px;
        position: relative;
        &::before {
          content: "bio";
          left: -10px;
          top: -10px;
          background: rgb(30, 30, 30);
          padding: 0 5px;
          border-radius: 5px;
          color: rgb(60, 60, 60);
          position: absolute;
        }
      }
      .repos-info {
        @include flexbox(center, center, row);
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;
        .public {
          span {
            color: $primary;
          }
        }
        .gists {
          span {
            color: $primary;
          }
        }
      }
    }
  }

  .my-profile-placeholder {
    @include transition-basic;
    @include flexbox(center, flex-start, column);
    min-width: 300px;
    width: 500px;
    height: auto;
    background: rgb(35, 35, 35);
    border-radius: 10px;
    position: sticky;
    top: 20px;
    padding: 20px;
    @include overflow;
    gap: 10px;
    @media screen and (max-width: 1560px) {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
    }
    .username {
      width: 80%;
      height: 20px;
      left: 0;
      align-self: flex-start;
      position: relative;
      border-radius: 5px;
      margin-bottom: 40px;
      animation: fade 3s ease infinite;
      @keyframes fade {
        0% {
          background: rgb(40, 40, 40);
        }
        50% {
          background: rgb(55, 55, 55);
        }
        100% {
          background: rgb(40, 40, 40);
        }
      }
    }
    .avatar {
      width: 100%;
      max-width: 100px;
      height: 100px;
      border-radius: 20px;
      position: absolute;
      right: -5px;
      top: -30px;
      outline: 10px solid rgb(35, 35, 35);
      @include transition-basic;

      animation: fade 3s ease infinite;
      @keyframes fade {
        0% {
          background: rgb(40, 40, 40);
        }
        50% {
          background: rgb(55, 55, 55);
        }
        100% {
          background: rgb(40, 40, 40);
        }
      }

      @media screen and (max-width: 1560px) {
        right: -20px;
        top: -45px;
      }
      @media screen and (max-width: 520px) {
        display: none;
      }
    }
    .info {
      @include flexbox(center, flex-start, column);
      width: 100%;
      height: auto;
      left: 0;
      align-self: flex-start;
      position: relative;
      border-radius: 5px;
      margin-bottom: 40px;
      gap: 40px;
      .name {
        width: 100%;
        height: 20px;
        left: 0;
        bottom: 0;
        align-self: flex-start;
        border-radius: 5px;
        animation: fade 3s ease infinite;
        @keyframes fade {
          0% {
            background: rgb(40, 40, 40);
          }
          50% {
            background: rgb(55, 55, 55);
          }
          100% {
            background: rgb(40, 40, 40);
          }
        }
      }
      .hireable {
        width: 100%;
        height: 20px;
        left: 0;
        bottom: 0;
        align-self: flex-start;
        border-radius: 5px;
        animation: fade 3s ease infinite;
        @keyframes fade {
          0% {
            background: rgb(40, 40, 40);
          }
          50% {
            background: rgb(55, 55, 55);
          }
          100% {
            background: rgb(40, 40, 40);
          }
        }
      }
      .bio {
        width: 100%;
        height: 40px;
        left: 0;
        bottom: 0;
        align-self: flex-start;
        border-radius: 5px;
        animation: fade 3s ease infinite;
        @keyframes fade {
          0% {
            background: rgb(40, 40, 40);
          }
          50% {
            background: rgb(55, 55, 55);
          }
          100% {
            background: rgb(40, 40, 40);
          }
        }
      }
      .repos-info {
        width: 100%;
        height: 50px;
        left: 0;
        bottom: 0;
        align-self: flex-start;
        border-radius: 5px;
        animation: fade 3s ease infinite;
        @keyframes fade {
          0% {
            background: rgb(40, 40, 40);
          }
          50% {
            background: rgb(55, 55, 55);
          }
          100% {
            background: rgb(40, 40, 40);
          }
        }
      }
    }
  }

  .repos-placeholder {
    @include flexbox(center, flex-start, column);
    flex: 1;
    @include scroll-y;
    padding: 20px;
    gap: 10px;
    @media screen and (max-width: 1560px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .repo-placeholder {
      width: 100%;
      height: 300px;
      padding: 20px;
      background: rgb(40, 40, 40);
      border-radius: 5px;
      animation: fade 3s ease infinite;
      @keyframes fade {
        0% {
          background: rgb(40, 40, 40);
        }
        50% {
          background: rgb(55, 55, 55);
        }
        100% {
          background: rgb(40, 40, 40);
        }
      }
    }
  }

  .repos {
    @include flexbox(center, flex-start, column);
    flex: 1;
    @include scroll-y;
    padding: 20px;
    gap: 10px;
    @media screen and (max-width: 1560px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .repo {
      width: 100%;
      padding: 20px;
      background: rgb(40, 40, 40);
      border-radius: 5px;
      .repo-title {
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        a {
          font-size: 1em;
        }
      }
      .description {
        @include flexbox(flex-start, flex-start, column);
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
        font-size: 0.8em;
        color: rgb(240, 240, 240);
        b {
          @include remove-user-interaction;
        }
        span {
          color: white;
          width: 100%;
          background: rgb(35, 35, 35);
          padding: 10px;
          border-radius: 5px;
          margin-top: 1px;
          font-size: 1.2em;
        }
      }
      .language {
        &.JavaScript {
          color: #f0dc55;
        }
        &.SCSS {
          color: #d2699e;
        }
        &.HTML {
          color: #e5532d;
        }
        &.Less {
          color: #1d365d;
        }
        &.Python {
          color: #4584b6;
        }
        .icon {
          width: 50px;
          height: 50px;
        }
      }
      .clone-url {
        @include flexbox(flex-start, flex-start, column);
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
        font-size: 0.8em;
        color: rgb(240, 240, 240);
        b {
          @include remove-user-interaction;
        }
        span {
          color: white;
          width: 100%;
          background: rgb(35, 35, 35);
          padding: 10px;
          border-radius: 5px;
          margin-top: 1px;
          font-size: 1.2em;
        }
      }
      .dates {
        @include flexbox(center, space-around, row);
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;
        color: rgb(230, 230, 230);
        * {
          span {
            color: white;
          }
        }
      }
    }
  }
}
