@import "colors";

@import "primary";
@import "primary-status";

$translate-center: translate(-50%, -50%);

$default-color: white;
$link-color: $primary;
$link-color-hover: $primary-darker;

$scrollbar-track-bg: transparent;
$scrollbar-width: 10px;

$loader-size: 48px;
$loader-rotate: rotateX(65deg) rotate(45deg);

$button-padding-default: 10px 20px;

$header-socials-social-background: $white-0-1;