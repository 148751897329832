@media (max-width: 768px) {
  .header-toggle {
    display: block;
    position: relative;
  }

  .header-navigation {
    flex-direction: column;
    position: absolute;
    top: -100%;
    right: 0;
    background-color: $primary-bg-dark;
    width: 100px;
    opacity: 0;

    &.active {
      opacity: 1;
      top: 100%;

      .toggle {
        display: block;
        color: $default-color;
        cursor: pointer;
        pointer-events: default;
        background: $purple-medium;
        padding: 15px 10px;
        font-size: 16px;
        &:hover {
          background: $purple-light;
        }
      }

      .downloads {
        width: 100%;
        display: block;
        .navigation-item {
          background: $purple-dark;
        }
      }

      .navigation-item {
        @include flexbox(center, center, none);
        text-align: center;
        width: 100%;
        padding: 0;
        @include transition-basic;

        &:hover {
          background: $primary;
        }

        .item-link {
          text-align: center;
          @include w-h-100;
          color: $default-color;
          text-decoration: none;
          position: relative;
          padding: 10px 5px;

          &:hover {
            color: $default-color;
          }
        }
      }
    }
  }
}
