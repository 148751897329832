.categories {
  min-width: 30%;
  max-width: 800px;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  background: rgba(35, 35, 35, 0.9);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  .categories-title {
    text-align: center;
    width: 100%;
    color: white;
    position: relative;
    font-weight: 500;
    font-size: 1.2em;
  }
  .links {
    @include flexbox(flex-start, center, row);
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    a {
      margin: 10px;
      background: rgb(65, 65, 65);
      padding: $button-padding-default;
      border-radius: 5px;
      &:hover {
        color: $purple-light;
        background: rgb(75, 75, 75);
      }
    }
  }
}
