.filter {
  @include flexbox(center, center, row);
  flex-wrap: wrap;
  padding: 10px 0;
  border-radius: 10px;
  height: fit-content;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.1);
  input {
    width: 90%;
    max-width: 300px;
    font-size: 16px;
    color: rgb(250, 250, 250);
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @include transition-basic;
    border: none;
    outline: none;
  }
  button {
    @include flexbox(center, center, row);
    background: transparent;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    margin-left: 2.5%;
    margin-top: 0.5%;
    cursor: pointer;
    position: relative;
    z-index: 1;
    @media (max-width: 429px) {
      margin-top: 2.5%;
    }
    &::after {
      content: "";
      width: 130%;
      height: 130%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      z-index: -1;
      position: absolute;
      @include transition-basic;
    }
    &:hover {
      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
    img {
      width: 80%;
    }
  }
}
