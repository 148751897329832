.filter {
  @include flexbox(center, center, row);
  flex-wrap: wrap;
  padding: 10px 0;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.1);
  input {
    font-size: 16px;
    color: rgb(250, 250, 250);
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: 100ms ease all;
    border: none;
    outline: none;
  }
}
