.title {
  text-align: center;
  width: 100%;
  color: white;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -10px;
    background: rgb(88, 88, 88);
    background: -moz-linear-gradient(
      90deg,
      rgba(88, 88, 88, 0) 0%,
      rgba(198, 198, 198, 1) 50%,
      rgba(88, 88, 88, 0) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(88, 88, 88, 0) 0%,
      rgba(198, 198, 198, 1) 50%,
      rgba(88, 88, 88, 0) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(88, 88, 88, 0) 0%,
      rgba(198, 198, 198, 1) 50%,
      rgba(88, 88, 88, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#585858",endColorstr="#585858",GradientType=1);
    position: absolute;
  }
}
