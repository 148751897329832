$screen-size_1: 760px;
$screen-size_2: 640px;
$screen-size_3: 546px;
$screen-size_4: 410px;
.page-container.art {
  @include flexbox();
  width: 100%;
  padding: 0 20px;
  padding-bottom: 400px;
  position: relative;
  .wrapper {
    .content {
      .main-showcase {
        @include flexbox();
        position: relative;
        .showcase-item {
          position: relative;
          &:hover {
            .image-wrapper {
              img {
                filter: brightness(50%);
              }
            }
            p {
              color: white;
            }
          }
          .image-wrapper {
            max-height: 400px;
            overflow: hidden;
            position: relative;
            @media screen and (max-width: $screen-size_2) {
                max-height: 200px;
            }
            img {
              max-width: 100vw;
              @include transition-basic;
              //   filter: brightness(30%);
            }
          }
          p {
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            font-size: 10em;
            position: absolute;
            z-index: +1;
            margin-left: 5%;
            overflow: visible;
            color: rgba(255, 255, 255, 0.2);
            @include transition-basic;
            cursor: default;
            pointer-events: none;
            line-height: 150px;
            @media screen and (max-width: $screen-size_1) {
                font-size: 8em;
            }
            @media screen and (max-width: $screen-size_2) {
                font-size: 5em;
            }
            @media screen and (max-width: $screen-size_3) {
                left: 50%;
                transform: translate(-70%, -50%);
                margin: 0;
                line-height: 80px;
            }
            @media screen and (max-width: $screen-size_4) {
                font-size: 3em;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: 0;
                line-height: 50px;
            }
          }
        }
      }
      h2 {
        margin-top: 100px;
        text-align: right;
        padding-right: 20px;
        font-size: 2em;
        color: rgba(255, 255, 255, 0.3);
        @media screen and (max-width: $screen-size_3) {
            margin-top: 50px;
        }
        @media screen and (max-width: $screen-size_4) {
            text-align: center;
        }
        &[section="concepts"] {
          margin-top: 0px;
        }
      }
      ul {
        @include flexbox(center, flex-start, row);
        flex-wrap: wrap;
        list-style: none;
        position: relative;
        gap: 50px;
        padding: 0;
        padding-right: 150px;
        padding-left: 50px;
        @media screen and (max-width: $screen-size_4) {
            padding: 0;
        }
        &::after {
          content: "";
          display: block;
          width: 2px;
          height: 100%;
          right: 100px;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(255, 255, 255, 0.2);
          position: absolute;
          @media screen and (max-width: $screen-size_4) {
            display: none;
          }
        }
        .item {
            &:hover {
                a {
                    img {
                        transform: rotate(-10deg);
                    }
                }
            }
          a {
            img {
              max-width: 200px;
              @include transition-basic;
              transform: rotate(0deg);
            }
          }
        }
      }
      .bio, .inspirations {
        @include flexbox(center, center, row);
        flex-wrap: wrap;
        gap: 50px;
        margin-top: 0;
        padding: 50px;
        width: 100%;
        @media screen and (max-width: $screen-size_4) {
            padding: 0;
        }
        .bio-text, .inspirations-text {
          $rgb: 35;
          background: rgb($rgb,$rgb,$rgb);
          border-radius: 10px;
          padding: 20px;
        }
      }
      h1 {
        text-align: right;
        font-size: 3em;
        font-weight: 500;
        margin-top: 50px;
        margin-bottom: -5px;
        padding-right: 50px;
        color: rgb(45,45,45);
      }
      .definitions-list, .inspirations-list {
        @include flexbox();
        // list-style: none;
        // position: relative;
        // gap: 50px;
        // padding: 50px;
        // margin: 0;
        // border-top: 2px solid rgb(45,45,45);
        li {
          width: 100%;
          @include flexbox(center, space-between, row);
          flex-wrap: nowrap;
          gap: 20px;
          .image-wrapper {
            img {
              max-height: 300px;
            }
          }
          .text {
            @include flexbox(flex-start, flex-start, column);
            h3 {
              font-size: 6em;
            }
          }
        }
      }
    }
  }
}
.line-loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: rgba(255, 255, 255, 0.15);
    overflow: hidden;
  }
  .line-loader::after {
    content: '';
    width: 192px;
    height: 4.8px;
    background: $primary;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
      