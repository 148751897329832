.page-section.tools {
  @include flexbox(center, center, column);
  padding: 20px 5vw 10vh 5vw;
  margin-top: -80px;
  margin-bottom: 5vh;
  transition: 100ms ease all;
  gap: 10px;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    position: absolute;
    z-index: -1;
  }

  .section-title {
    margin-top: 10%;
  }
  
  .disclaimer {
    padding: 10px 20px;
    border-radius: 10px;
    background: rgb(40,40,40);
  }

  .section-downloads {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    align-items: stretch;
    justify-content: center;
    gap: 20px;
    width: 90%;
    padding: 20px;
    border-radius: 5px;

    .download-item {
      position: relative;
      @include flexbox(center, flex-start, column);
      padding: 20px;
      background: rgb(35, 35, 35);
      outline: 2px solid $purple;
      border-radius: 5px;
      height: 100%;

      .text {
        font-size: 1.2em;
        margin-bottom: 10px;
      }

      .desc {
        font-weight: 400;
        margin-bottom: 10px;
      }

      button {
        border: none;
        outline: none;
        cursor: pointer;
        margin-top: auto;
        width: 100%;
        @include flexbox(center, center, none);
        text-align: center;
        padding: 10px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.05);
        text-decoration: none;
        color: white;
        @include transition-basic;

        &:hover {
          background: $purple;
        }

        img {
          width: 20px;
          margin-left: 10px;
        }
      }
    }
  }
}
