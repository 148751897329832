.page-container.os-downloads {
  @include flexbox(center, flex-start, column);
  width: 100%;
  padding: 0 20px;
  padding-bottom: 400px;
  position: relative;

  @import "title";
  @import "filter";
  @import "downloads";
}
