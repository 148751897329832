@media (max-width: 1070px) {
  .page-section.home {
    .section-name {
      .section-title {
        font-size: 9em;
      }
    }
  }
}

@media (max-width: 963px) {
  .page-section.home {
    .section-name {
      .section-title {
        font-size: 8em;
      }
    }
  }
}

@media (max-width: 860px) {
  .page-section.home {
    .section-name {
      .section-title {
        font-size: 7em;
      }
    }
  }
}

@media (max-width: 805px) {
  .page-section.home {
    @include flexbox(center, flex-start, column);

    .section-name {
      .skills {
        margin-top: 5vh;
        .skills-wrapper {
          align-items: flex-start;
          justify-content: center;
        }
      }
    }

    .section-description {
      margin-top: 0;

      .dec {
        display: none;
      }
    }
  }

  .page-section.about {
    .queries {
      .section-link {
        .arrow {
          margin-left: 10%;
        }
      }
    }
  }
}

@media (max-width: 770px) {
  .page-section.home {
    @include flexbox(center, flex-start, column);
    margin-bottom: 0;
    padding-bottom: 0;

    .section-name {
      text-align: center;
      @include remove-previous-margin-padding;

      .section-title {
        font-size: 7.5em;
        padding: 0;
        left: 0;
        position: relative;
      }

      .skills {
        margin-bottom: 5vh;
      }
    }

    .section-description {
      width: 80%;
      @include fit-content-height;
      margin: 0;
      margin-bottom: 100px;
      justify-content: flex-start;
      padding: 100px;
      border-radius: 5px;
      background: rgb(35, 35, 35);
    }
  }

  .page-section.about {
    margin-top: 0;
    top: 0;
    padding-left: 5vw;
  }
}

@media (max-width: 680px) {
  .page-section.about {
    @include flexbox();
    padding-bottom: 10vh;

    .about-me,
    .queries {
      width: 90%;
    }

    .queries {
      text-align: center;
    }
  }
}

@media (max-width: 570px) {
  .page-section.home {
    .section-description {
      padding: 40px;
    }
  }
}

@media (max-width: 510px) {
  .page-section.about {
    .queries {
      .section-link {
        .arrow {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .page-section.home {
    .section-name {
      .section-title {
        font-size: 5em;
      }
    }

    .section-description {
      width: 100%;
      border-radius: 0;
      margin: 0;
    }
  }

  .page-section.about {
    .queries {
      @include flexbox(center, center, column);

      .section-link {
        font-size: 1.2em;
        margin-left: 15px;

        .arrow {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .page-section.works {
    .works-section {
      .work-item {
        width: 100%;
        margin: 0 0 10px 0;

        &:nth-last-child(1) {
          margin: 0;
        }
      }
    }
  }
}
