.global-general {
  @include flexbox(center, flex-start, column);
  width: 100%;
  padding: 0 20px;
  padding-bottom: 400px;
  position: relative;
  &-page {
    &-list {
      &.no-ul {
        @include flexbox(center, flex-start, column);
        width: 100%;
        padding: 0 20px;
        padding-bottom: 400px;
        padding-top: 10%;
        position: relative;
        gap: 10px;
      }
    }
  }
}
