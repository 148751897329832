.page-container.dev {
  @include flexbox(center, flex-start, column);
  width: 100%;
  padding: 0 20px;
  position: relative;
  .title {
    font-size: 2em;
  }
  .dev-main {
    width: 100%;
    height: auto;
    padding: 10px;
    .links {
      $b-r: 10px;

      width: 100%;
      @include flexbox(center, flex-start, column);
      position: relative;
      background: $primary-darker;
      border-radius: calc($b-r + 2px);
      @media screen and (max-width: 500px) {
        gap: 5px;
      }
      &::after {
        content: "";
        width: 10px;
        height: 100%;
        right: 0;
        top: 0;
        background: $primary;
        position: absolute;
        border-radius: 0 $b-r $b-r 0;
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
      .item {
        font-size: 1.8em;
        font-weight: 500;
        @include flexbox(center, space-between, row);
        flex-wrap: nowrap;
        width: 100%;
        padding: 20px 30px;
        background: rgb(35, 35, 35);
        position: relative;
        @include transition-basic;
        @media screen and (max-width: 500px) {
          font-size: 2em;
          text-align: center;
          justify-content: center;
        }
        &:hover {
          background: rgb(32, 32, 32);
          .arrow {
            right: 0;
          }
        }
        &:nth-child(1) {
          border-radius: $b-r $b-r 0 0;
        }
        &:nth-last-child(1) {
          border-radius: 0 0 $b-r $b-r;
        }
        &.b-r-a {
          border-radius: $b-r;
        }
        .arrow {
          width: 50px;
          height: 50px;
          right: 5%;
          position: relative;
          transition: 1s ease all;
          @media screen and (max-width: 500px) {
            display: none;
          }
          .img {
            position: relative;
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}
