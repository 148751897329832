@import "./preload-connector";

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  a {
    color: $link-color;
    cursor: pointer;
    @include transition-basic;
    text-decoration: none;
    &:hover {
      color: $link-color-hover;
      text-decoration: none;
    }
  }
}

:root {
  font-family: "Hanken Grotesk", sans-serif !important;
}

body {
  @include remove-previous-margin-padding;
  color: $default-color;
  @include scroll-y;
  background: $primary-bg;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }
  &::-webkit-scrollbar-track {
    background: $scrollbar-track-bg;
  }
}

@import "./page-connector";
