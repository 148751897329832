.modal {
  @include flexbox(center, flex-start, column);
  width: 100%;
  max-width: 400px;
  min-width: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  height: -moz-fit-content;
  position: fixed;
  background: rgb(25, 25, 25);
  z-index: 99;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  .modal-strong-text {
    text-align: center;
    font-weight: 800;
    font-size: 1.5em;
  }
  .modal-button-close {
    @include button-basic-primary;
    background: $primary-error-red;
    @include transition-basic;
    border-radius: 5px;
    &:hover {
        background: $primary-error-red-darker;
    }
  }
}
.modal-background-blur {
  position: fixed;
  @include l-t-0;
  @include w-h-100;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 98;
}
