@mixin remove-previous-margin-padding {
  margin: 0;
  padding: 0;
}
@mixin w-h-100 {
  width: 100%;
  height: 100%;
}
@mixin s-50 {
  width: 50px;
  height: 50px;
}
@mixin s-min-50 {
  min-width: 50px;
  min-height: 50px;
}
@mixin l-t-50 {
  left: 50%;
  top: 50%;
}
@mixin l-t-0 {
  left: 0;
  top: 0;
}
@mixin r-t-0 {
  right: 0;
  top: 0;
}
@mixin scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
}
@mixin overflow-none {
  overflow: hidden;
}
@mixin overflow {
  overflow: visible;
}
@mixin flexbox($align: center, $justify: flex-start, $direction: column) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}
@mixin fit-content-height {
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
}
@mixin fit-content-width {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

@import "./user-interaction/user-interaction";
