.downloads {
  @include flexbox(flex-start, center, row);
  flex-wrap: wrap;
  gap: 10px;
  a {
    color: rgb(250, 250, 250);
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: 100ms ease all;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}
