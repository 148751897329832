.page-section.home {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";

  .section-name {
    @include flexbox(center, space-between, column);
    position: relative;
    z-index: 1;
    padding: 10px;

    .section-title {
      @include transition-basic;
      left: 0;
      top: 0;
      width: 100%;
      display: inline-block;
      font-size: 10em;
      font-weight: 900;
      line-height: 0.8em;
      margin: 0;
      margin-top: 10%;
      padding-left: 5vw;
      white-space: normal;
      word-wrap: break-word;

      span {
        font-size: 0.5em;
      }
    }

    .skills {
      @include flexbox(center, flex-start, column);
      width: 100%;
      margin-top: 10px;
      margin-bottom: 0;
      gap: 10px;

      .skills-wrapper {
        @include flexbox(center, flex-start, row);
        flex-wrap: wrap;
        width: 70%;
        padding: 10px 0;
        gap: 10px;

        .skills-item {
          width: 50px;
          @include flexbox(center, center, none);
          border-radius: 8px;
          padding: 5px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          background: rgb(35, 35, 35);
          transition: transform 0.3s ease-in-out;

          opacity: 0;
          transform: translateY(20px);

          &:hover {
            transform: translateY(-5px) !important;
            .tooltiptext {
              transform: scale(1);
            }
          }

          &.show {
            opacity: 1;
            transform: translateY(0);
          }

          .item-image {
            width: 100%;
            &.image-loading {
              border-radius: 100%;
            }
          }
          .tooltiptext {
            width: max-content;
            top: -100%;
            color: white;
            z-index: 99;
            position: absolute;
            padding: 10px 20px;
            border-radius: 10px;
            pointer-events: none;
            @include transition-basic;
            transform: scale(0);
            background: rgb(35, 35, 35);
          }
        }
      }

      @media (max-width: 600px) {
        .skills-wrapper {
          width: 80vw;
          margin-bottom: 20px;
        }
      }

      @media (max-width: 550px) {
        @include flexbox(center, flex-start, column);
      }
    }
  }

  .section-description {
    position: relative;
    z-index: 2;
    @include flexbox(flex-start, flex-end, column);
    padding: 20px 80px;
    background: rgba(0, 0, 0, 0);

    /* Blinking cursor */
    .cursor {
      display: inline-block;
      width: 3px; /* Adjust width of the cursor */
      height: 1em; /* Adjust height of the cursor */
      background-color: white; /* Color of the cursor */
      margin-left: 5px;
      &.blinking {
        animation: blink-animation 1s step-end infinite;
      }
    }

    @keyframes blink-animation {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }

    .dec {
      position: relative;
      width: 100px;
      height: 100px;
      top: 5%;
      margin-bottom: 15vh;
      transform: rotate(-25deg);

      &::before {
        content: "";
        $size: 3em;
        width: 0;
        height: 0;
        top: -50%;
        left: -0;
        border: $size solid transparent;
        border-bottom-color: rgb(100, 100, 100);
        position: absolute;
      }

      &::after {
        content: "";
        width: 35px;
        height: 35px;
        left: 50%;
        bottom: 5px;
        transform: translateX(-50%);
        border-radius: 50%;
        background: $primary;
        position: absolute;
      }
    }

    .type {
      @include flexbox(center, none, none);
      width: 50%;

      a {
        @include link-basic;
        text-decoration: underline;
        font-size: 0.8em;
        position: relative;

        &::after {
          content: "";
          width: 40%;
          height: 2px;
          top: 50%;
          margin-left: 20px;
          transform: translateY(-50%);
          background: $primary;
          position: absolute;
        }
      }
    }

    .description-text {
      font-size: 1em;
    }

    .description-link {
      @include link-basic;
      font-size: 0.9em;
      line-height: 1.8em;
    }
  }
}
