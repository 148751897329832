.user-agent-display {
  @include flexbox(center, center, column);
  @include s-50;
  @include s-min-50;
  border-radius: 50%;
  padding: 10px;
  opacity: 0.9;
  position: relative;
  @include transition-basic;
  cursor: pointer;
  &:hover {
    opacity: 0.1;
  }
  img {
    width: 100%;
    pointer-events: none;
    &.firefox {
      width: 140%;
    }
    &.safari {
      width: 140%;
    }
    &.opera {
      width: 140%;
    }
    &.chrome {
      width: 140%;
    }
    &.edge {
      width: 140%;
    }
    &.unknown-browser {
      width: 140%;
    }
  }
  .user-agent-container {
    &.desktop-false {
      @include flexbox(center, center, none);
      @include w-h-100;
      @include l-t-0;
      position: absolute;
      img {
        width: 70% !important;
      }
    }
    &.desktop-true {
      width: 210%;
      height: 100%;
      @include r-t-0;
      background: white;
      border-radius: 60px;
      position: absolute;
      img {
        height: 90%;
        top: 2.5px;
        right: -45%;
        position: absolute;
      }
      .desktop {
        padding: 8px;
        width: 50px;
        left: 2px;
        top: 2.5px;
        position: absolute;
      }
    }
  }
}
