&.github-auth {
  @include flexbox(center, center, column);
  .card {
    @include flexbox(center, space-between, column);
    max-width: 400px;
    min-height: 200px;
    padding: 20px;
    background: rgb(35, 35, 35);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .sub-heading {
      margin-top: 0;
      padding: 10px;
    }
    .actions {
      width: 100%;
      @include flexbox(center, space-around, row);
      position: relative;
      gap: 20px;
      .option {
        font-size: 0.9em;
        text-align: center;
        width: 50%;
        color: white;
        padding: 10px;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        @include transition-basic;
        &:hover {
          filter: brightness(90%);
        }
        &.continue {
          background: $primary-success-green-darker;
        }
        &.return-home {
          background: $primary-darker;
        }
      }
    }
  }
}
