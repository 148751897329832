.header-toggle {
  display: none;

  .toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    @include transition-basic;
    position: relative;

    img {
      width: 50px;
    }

    &:hover {
      &::before {
        transform: scale(1);
      }
    }

    &::before {
      content: "";
      @include w-h-100;
      @include l-t-0;
      border-radius: 100%;
      transform: scale(0);
      @include transition-basic;
      background: rgba(255, 255, 255, 0.1);
      position: absolute;
      z-index: 1;
    }
  }
}
