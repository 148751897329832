.image-loading {
  transform: scale(1);
  animation: zoomLoop 2s ease infinite;
  @keyframes zoomLoop {
    0% {
      transform: scale(0.5);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.5);
    }
  }
}
