.page-container.search {
  @include flexbox(center, flex-start, column);
  width: 100%;
  padding: 0 20px;
  position: relative;
  margin-bottom: 400px;
  .title {
    font-size: 2em;
  }
  .search-form {
    @include flexbox(center, flex-start, column);
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border-radius: 5px;
    border-bottom: 3px solid $primary-darker;
    input[type="text"] {
      background: rgb(35, 35, 35);
      border: none;
      outline: none;
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
  .search-results {
    max-width: 400px;
    min-width: 200px;
    @include flexbox(center, flex-start, column);
    margin-top: 10px;
    padding: 10px;
    gap: 10px;
    width: 80%;
    .result {
      @include flexbox(center, center, row);
      width: 100%;
      a {
        @include flexbox(center, center, row);
        text-wrap: wrap;
        word-break: break-all;
        width: 100%;
        padding: 5px 10px;
        background: rgb(40, 40, 40);
        min-height: 50px;
        border-radius: 5px;
        @include transition-basic;
        &:hover {
          background: rgb(45, 45, 45);
        }
      }
    }
  }
}
