header.header#header {
  color: $default-color;
  @include flexbox(center, none, none);
  padding: $button-padding-default;
  position: relative;

  @import "header-logo";
  @import "header-toggle";
  @import "header-navigation";
  @import "header-socials";

  @import "header-media";

}

@import "media";
