&.sub-page {
  .wrapper {
    width: 80%;
    background: rgb(35, 35, 35);
    border-radius: 10px;
    .title-wrapper {
      text-align: center;
      margin-bottom: 10px;
    }
    .info {
      width: 100%;
      padding: 20px;
      border-top: 2px solid rgb(45,45,45);
    }
  }
}
