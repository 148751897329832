.live-popup {
  @include flexbox(center, center, column);
  gap: 10px;
  position: fixed;
  z-index: 1000000;
  width: 80%;
  max-width: 400px;
  min-width: 200px;
  height: max-content;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  @include transition-basic;
  transform: translate(-50%, -50%) scale(1);
  background: rgb(35, 35, 35);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  word-wrap: normal;
  .buttons {
    @include flexbox(center, center, row);
    gap: 10px;
    button {
      font-weight: 600;
      padding: 10px 20px;
      cursor: pointer;
      outline: none;
      border: none;
      background: $primary;
      border-radius: 5px;
      @include transition-basic;
      &:hover {
        background: $primary-darker;
        &.continue {
          background: $primary-success-green;
        }
        &.cancel {
          background: $primary-error-red;
        }
      }
    }
  }
}
.overlay {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  position: fixed;
  z-index: 19000;
}
