.header-navigation {
  @include flexbox(center, center, none);
  @include remove-previous-margin-padding;
  width: 100%;
  list-style: none;

  &.active {
    z-index: 99;
  }

  .toggle {
    display: none;
    width: 100%;
    border: none;
    padding: 5px;
    .download {
      width: 100%;
      border: none;
    }
  }

  .downloads {
    @include flexbox(center, center, row);
  }

  .navigation-item {
    @include remove-previous-margin-padding;
    padding: 0 20px;

    .item-link {
      color: white;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }
}
