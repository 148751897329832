.page-section.about {
  padding-bottom: 10vh;
  padding-top: 5vw;
  margin-top: 20px;
  background: rgb(25, 25, 25);
  padding-left: 10vw;
  padding-right: 10vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";

  .about-me {
    .section-link {
      text-decoration: underline;
      font-size: 0.8em;
      display: inline-block;
      margin-top: 10px;
      color: $primary; // Your primary color variable
      position: relative;

      &::after {
        $width: 70%;

        content: "";
        width: $width;
        height: 2px;
        top: 50%;
        right: calc(($width * -1) + ($width/4 * -1));
        transform: translateY(-50%);
        background: $primary;
        position: absolute;
      }
    }
  }

  .queries {
    .section-title {
      font-size: 3em;
    }

    .section-link {
      font-size: 1.7em;
      display: inline-block;
      margin-top: 10px;
      color: $primary; // Your primary color variable
      text-decoration: none;
      position: relative;

      .arrow {
        display: inline-block;
        width: 10px;
        height: 10px;
        top: 45%;
        transform: translateY(-50%);
        margin-left: 30%;
        border-top: 2px solid $primary; // Your primary color variable
        border-right: 2px solid $primary; // Your primary color variable
        transform: rotate(45deg);
        position: absolute;
      }
    }
  }

  .about-me,
  .queries {
    max-width: 800px; // Adjust the maximum width
    margin: 0 auto;
    padding: 20px;
  }
}
