&.bg-image {
  * {
    z-index: 2;
  }
  .title {
    $transform-translate: translateX(-50%);
    width: auto;
    margin-top: 5vh;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    background: rgba(35, 35, 35, 0.9);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    position: relative;
  }
  .background-container {
    width: 100%;
    height: 100vh;
    min-height: 300px;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    @include overflow-none;
    position: absolute;
    z-index: 1;
    img {
      filter: blur(5px);
      width: auto;
      min-width: 100%;
      min-height: 100%;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: inherit;
      transition: 100ms ease all;
    }
  }
}
