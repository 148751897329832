.page-section.works {
  @include flexbox(center, center, column);
  padding: 5vh 0 10vh 0;
  margin-top: -50px;
  margin-bottom: 0;

  .not-working-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: +1;
    background: $red-dark-transparent;
    backdrop-filter: blur(5px);
    border-radius: 5px;
    position: absolute;
    pointer-events: default;
    cursor: default;
    strong {
      font-size: 2em;
    }
  }

  .section-name {
    color: white;
    text-decoration: none;
    text-align: center;
    width: 100%;
    background: $primary;
    padding: 0 20px 0 20px;
    @include transition-basic;

    .section-title {
      font-size: 2.2em;
    }
  }

  .works-section {
    width: 100%;
    background: rgb(35, 35, 35);
    padding: 20px;
    position: relative;

    .work-items {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 20px;

      @import "pages";

      &.iframe-full-width {
        @include flexbox(center, flex-start, column);
        .work-item {
          &.iframe {
            width: 100% !important;
            height: 100% !important;
            min-height: 200px;
            padding: 0 !important;
            a {
              width: 100% !important;
              height: 100% !important;
              padding: 0 !important;
              .website-preview {
                width: 100% !important;
                height: 100% !important;
                iframe {
                  width: 100% !important;
                  height: 400px !important;
                }
              }
            }
          }
        }
      }

      .work-item {
        @include flexbox(center, center, none);
        text-decoration: none;
        color: white;
        border-radius: 5px;
        @include transition-basic;
        outline: none;
        background: rgba(255, 255, 255, 0.05);

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        .item-title {
          text-align: center;
          word-wrap: break-word;
          padding: 0 20px;
          color: white;
          z-index: +2;
          span {
            text-align: center;
            word-wrap: break-word;
          }
        }

        .item-image {
          width: 100%;
          border-radius: 5px;
          transform: scale(0);

          @include transition-basic;
        }
        .item-image--loaded {
          transform: scale(1);
        }
      }

      &.images {
        .livePreviewToggle {
          @include flexbox(center, center, none);
          margin: 10px;
          padding: 0 5px;
          width: 35px;
          height: 25px;
          border-radius: 5px;
          background: $primary;
          outline: none;
          border: none;
          position: absolute;
          z-index: 99;
          cursor: pointer;
          @include transition-basic;
          &:hover {
            background: $primary-darker;
            .tooltiptext {
              transform: scale(1);
            }
          }
          &.close {
            background: $primary-error-red;
            &:hover {
              background: $primary-error-red-darker;
            }
          }
          @media screen and (max-width: 559px) {
            width: 45px;
            height: 45px;
            img {
              width: 35px !important;
            }
          }
          img {
            width: 25px;
          }
          .tooltiptext {
            width: max-content;
            top: -55px;
            left: -20px;
            color: white;
            z-index: 99;
            position: absolute;
            padding: 10px 20px;
            border-radius: 10px;
            pointer-events: none;
            @include transition-basic;
            transform: scale(0);
            background: rgb(45, 45, 45);
          }
        }
        .work-item {
          display: block;
          text-decoration: none;
          color: white;
          border-radius: 5px;
          @include transition-basic;
          outline: 1px solid transparent;
          background: rgba(255, 255, 255, 0.05);
          position: relative;
          box-shadow: 0 0 0 transparent;

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
            outline: 2px solid $primary;
            .item-title {
              bottom: -25px;
              left: 10px;
              border-radius: 5px;
              @media screen and (max-width: 1260px) {
                left: 0;
                bottom: 0;
              }
              @media screen and (max-width: 559px) {
                border-radius: 0 !important;
              }
            }
          }

          @media screen and (max-width: 1260px) {
            @include overflow-none;
          }

          @media screen and (max-width: 559px) {
            height: 200px;
            @include overflow-none;
            .item-title {
              width: 100%;
              padding: 10px 20px;
              border-radius: 0 !important;
            }
            .item-image {
              width: 100%;
              height: max-content !important;
            }
          }

          .website-preview {
            height: 213px;
            iframe {
              height: 100%;
              width: 100%;
              pointer-events: none;
              @include remove-user-interaction;
            }
          }

          .item-title {
            background: $primary-darker;
            border-radius: 0 5px 5px 0;
            padding: 5px 20px;
            word-wrap: break-word;
            position: absolute;
            bottom: 0;
            left: 0;
            @include transition-basic;
            @media screen and (max-width: 1200px) {
              border-radius: 0 5px 0 0 !important;
              bottom: 0;
              margin: 0;
            }
            @media screen and (max-width: 1167px) {
              width: 100%;
              border-radius: 0 !important;
            }
          }
        }
      }
    }
  }
}
