&.boyfriend {
  .server-card {
    width: 100%;
    @include fit-content-height;
    padding: 10px;
    position: relative;
    .title {
      width: 100%;
      text-align: center;
      font-size: 3em;
    }
    .basic-info {
      @include flexbox(center, center, column);
      width: 100%;
      gap: 10px;
      .online-members {
        width: 50%;
        min-width: 200px;
        max-width: 400px;
        padding: 10px 20px;
        border-radius: 5px;
        background: rgb(65, 65, 65);
        margin: 0;
      }
      .total-members {
        width: 50%;
        min-width: 200px;
        max-width: 400px;
        padding: 10px 20px;
        border-radius: 5px;
        background: rgb(65, 65, 65);
        margin: 0;
      }
      .join {
        max-width: 200px;
        text-align: center;
        display: inline-block;
        color: white;
        background: $primary;
        padding: 10px 20px;
        border-radius: 5px;
        margin-top: 10px;
        cursor: pointer;
        position: relative;
        width: 100%;
        box-sizing: border-box; /* Ensure padding and borders are included in width */
      }
    }
    .members-title {
      width: 100%;
      padding: 15px 20px;
      position: relative;

      $line: rgb(55, 55, 55);

      &::before {
        content: "";
        width: 100%;
        height: 5px;
        top: 0;
        left: 0;
        z-index: 99;
        background: $line;
        border-radius: 5px;
        position: absolute;
      }
    }
    .members {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 20px;
      margin-top: 20px;
      padding: 10px;
      .member {
        height: fit-content;
        background: rgb(35, 35, 35);
        border-radius: 5px;
        padding: 10px 20px;
        img {
          width: 100%;
          max-width: 100px;
          border-radius: 100%;
          &.loading {
            transform: scale(1);
            animation: zoomLoop 2s ease infinite;
            @keyframes zoomLoop {
              0% {
                transform: scale(0.5);
              }
              75% {
                transform: scale(1);
              }
              100% {
                transform: scale(0.5);
              }
            }
          }
        }
        .game {
          background: rgb(50, 50, 50);
          padding: 10px 20px;
          border-radius: 5px;
          span {
            color: $primary;
          }
        }
      }
    }
  }
}
