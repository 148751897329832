.header-socials {
  @include flexbox(center, center, row);
  padding: 0;
  a {
    @include flexbox(center, center, none);
    @include remove-previous-margin-padding;
    margin-right: 20px;
    position: relative;

    &:nth-last-child(1) {
      margin-right: 0;
    }

    &:hover {
      &::before {
        transform: $translate-center scale(1);
      }
    }

    &::before {
      content: "";
      @include s-50;
      @include l-t-50;
      transform: $translate-center scale(0);
      background: $header-socials-social-background;
      z-index: -1;
      border-radius: 50%;
      position: absolute;
      @include transition-basic;
    }

    img {
      width: 30px;
      @include remove-previous-margin-padding;

      &#LinkedIn {
        width: 35px;
      }
    }
  }
}
