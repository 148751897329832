.ua-container {
  @include flexbox(center, flex-end, none);
  height: 100px;
  bottom: 0;
  right: 0;
  padding: 20px;
  position: fixed;
  z-index: 99;
  @import "ad-user-agent-display";
  @import "user-agent-display-tooltip";
}
