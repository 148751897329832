@mixin remove-user-interaction-advanced {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  -ms-touch-select: none !important;
  user-select: none !important;
  pointer-events: none;
  cursor: default;
  &::selection {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &::-moz-selection {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &::-webkit-keygen-select {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &:-moz-user-disabled {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &:user-invalid {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &:user-valid {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
}
@mixin remove-user-interaction-basic {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  -ms-touch-select: none !important;
  user-select: none !important;
  pointer-events: none;
  cursor: default;
  &::selection {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &::-moz-selection {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &::-webkit-keygen-select {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &:-moz-user-disabled {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &:user-invalid {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
  &:user-valid {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
    pointer-events: none;
    cursor: default;
  }
}
