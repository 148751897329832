.page-section.footer {
  @include flexbox(center, center, column);
  margin-top: 0;
  margin-bottom: 0;
  gap: 10px;
  position: relative;
  background: rgb(25, 25, 25);
  span {
    color: rgba(255, 255, 255, 0.6);
  }
}
