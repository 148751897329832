@mixin link-basic {
  color: $primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@mixin transition-basic {
  transition: 100ms ease all;
}

@mixin button-basic-primary {
  padding: 5px 20px;
  color: white;
  border: none;
  outline: none;
  background: $primary;
  cursor: pointer;
}

@mixin striped-background-10px($color1, $color2) {
  background: repeating-linear-gradient(
    45deg,
    #{$color1},
    #{$color1} 10px,
    #{$color2} 10px,
    #{$color2} 20px
  );
}

@mixin remove-user-interaction {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -webkit-user-drag: none !important;
  -webkit-user-modify: none !important;
  -moz-user-modify: none !important;
  -moz-user-input: none !important;
  -moz-user-focus: none !important;
  -ms-user-select: none !important;
  -ms-touch-select: none !important;
  user-select: none !important;
  &::selection {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-modify: none !important;
    -moz-user-modify: none !important;
    -moz-user-input: none !important;
    -moz-user-focus: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
  }
  &::-moz-selection {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-modify: none !important;
    -moz-user-modify: none !important;
    -moz-user-input: none !important;
    -moz-user-focus: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
  }
  &::-webkit-keygen-select {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-modify: none !important;
    -moz-user-modify: none !important;
    -moz-user-input: none !important;
    -moz-user-focus: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
  }
  &:-moz-user-disabled {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-modify: none !important;
    -moz-user-modify: none !important;
    -moz-user-input: none !important;
    -moz-user-focus: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
  }
  &:user-invalid {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-modify: none !important;
    -moz-user-modify: none !important;
    -moz-user-input: none !important;
    -moz-user-focus: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
  }
  &:user-valid {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-modify: none !important;
    -moz-user-modify: none !important;
    -moz-user-input: none !important;
    -moz-user-focus: none !important;
    -ms-user-select: none !important;
    -ms-touch-select: none !important;
    user-select: none !important;
  }
}
@mixin mobile-text-size {
  @media screen and (max-width: 1000px) {
    font-size: 16px !important;
  }
}

@import "./refactors/refactors";
