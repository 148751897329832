.page-container.press {
  @include flexbox(center, flex-start, column);
  width: 100%;
  padding: 0 20px;
  padding-top: 20px;
  margin-bottom: 200px;
  position: relative;
  .news {
    margin-top: 5vh;
    width: 100%;
    .top-row {
      width: 100%;
      @include flexbox(center, center, row);
      flex-wrap: wrap;
      gap: 20px;
      padding: 10px;
      .card {
        @include flexbox(center, flex-start, column);
        max-width: 400px;
        min-width: 300px;
        background: rgb(35, 35, 35);
        padding: 20px;
        min-height: 150px;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        @media screen and (max-width: 1320px) {
          &[data-is-active="false"] {
            display: none;
          }
        }
        * {
          z-index: +2;
        }
        .title {
          font-size: 2em;
          padding: 0;
          margin: 0;
        }
        .bg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: +1;
        }
      }
    }
    .sources {
      @include flexbox(center, flex-start, column);
      width: 100%;
      .sub-title {
        width: 100%;
        text-align: center;
        font-size: 2em;
        position: relative;
        z-index: +2;
        .text {
          $p: 20px;
          padding-left: $p;
          padding-right: $p;
          background: #323232;
          z-index: 4;
        }
        .line {
          width: 100%;
          height: 5px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          background: $primary;
          position: absolute;
          z-index: -1;
        }
      }
      .notice {
        max-width: 800px;
        background: rgb(35, 35, 35);
        padding: 10px 20px;
        margin-top: 20px;
        margin-bottom: 40px;
        border-radius: 5px;
        @include mobile-text-size;
      }
      .wrapper {
        width: 100%;
        @include flexbox(center, flex-start, column);
        gap: 20px;
        .source {
          @include flexbox(center, space-between, row);
          width: 100%;
          max-width: 800px;
          padding: 0 20px;
          position: relative;
          background: rgb(65, 65, 65);
          border-radius: 5px;
          @include transition-basic;
          &:hover {
            background: rgb(70, 70, 70);
          }
          .title {
            font-size: 1.2em;
            margin: 0;
            padding: 0;
            @include mobile-text-size;
          }
          .info {
            @include flexbox(center, center, row);
            gap: 20px;
            .use {
              @include flexbox(center, center, row);
              gap: 10px;
              .img {
                width: 50px;
                height: 50px;
                margin: 0;
                padding: 0;
                &.tor {
                  padding: 0px;
                }
                &.clearnet {
                  padding: 5px;
                }
              }
            }
            .status {
              .img {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}
