&.pages {
  @include flexbox(center, flex-start, column);
  .search-title {
    width: 100%;
    @include flexbox(center, space-between, row);
    @include fit-content-height;
    padding: 10px;
    gap: 0;
    border-bottom: 1px solid white;
    .title.pages {
      @include fit-content-width;
      @include remove-previous-margin-padding;
      margin-right: 10px;
      border-bottom: none;
    }
    .search-box {
      .search {
        $s: 50px;

        @include flexbox(center, center, none);
        width: $s;
        height: $s;
        margin: 0;
        border-radius: 100%;
        background: rgb(55, 55, 55);
        position: relative;
        @include transition-basic;
        box-shadow: 0 0 0 $primary;
        &:hover {
          background: rgb(65, 65, 65);
          transform: translateY(-2px);
          box-shadow: 0 2px 0 $primary;
        }
        &:target,
        &:focus,
        &:active {
          background: rgb(70, 70, 70);
        }
        .search-icon {
          $w: 30px;
          width: $w;
          height: $w;
        }
      }
    }
  }
  .pages-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
  }
  .more {
    margin-top: 20px;
    width: 100%;
    max-width: 500px;
    position: relative;
    &::after {
      content: "";
      width: 100vw;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      top: -20px;
      border-radius: 5px;
      background: rgb(40, 40, 40);
      position: absolute;
    }
    .search {
      padding: 10px;
      @include flexbox(center, center, none);
      width: 100%;
      border-radius: 10px;
      background: rgb(50, 50, 50);
      &:hover {
        background: rgb(55, 55, 55);
      }
      .search-icon {
        width: 50px;
        height: 50px;
      }
    }
  }
}
