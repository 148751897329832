.downloads {
  width: 100%;
  @include flexbox(flex-start, center, row);
  flex-wrap: wrap;
  gap: 10px;
  .item {
    width: 80%;
    color: rgb(250, 250, 250);
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @include transition-basic;
    @media screen and (max-width: 510px) {
      width: 100%;
    }
    b {
      $default-font-weight: bold;

      font-weight: $default-font-weight;

      padding: 5px;
    }
    .name {
      display: block;
      font-size: 1.2em;
      font-weight: 500;
      margin-bottom: 10px;
      // @include remove-user-interaction-basic;
      // pointer-events: none;
      // cursor: default;
    }
    .status {
      $local-orange: rgba(255, 179, 0, 0.4);
      $local-orange-dark: rgba(191, 135, 4, 0.4);
      $local-orange-2: rgba(255, 99, 0, 0.2);
      $local-orange-2-dark: rgba(207, 85, 8, 0.2);
      $local-red: rgba(245, 10, 10, 0.3);
      $local-red-dark: rgba(219, 9, 9, 0.3);
      $local-green: rgba(57, 237, 12, 0.2);
      $local-green-dark: rgba(51, 212, 11, 0.2);

      display: block;
      word-wrap: break-word;
      word-break: break-all;
      text-wrap: wrap;
      padding: 10px 20px;
      margin: 10px;
      border-radius: 5px;
      @include overflow-none;
      position: relative;
      &.d {
        color: white;
        @include striped-background-10px($local-orange-2, $local-orange-2-dark);
        z-index: 2;

        &::after {
          content: "IN-DEV";
          position: absolute;
          top: 40%;
          right: 0;
          color: black;
          transform: translateY(-50%);
          font-size: 5em;
          font-weight: 900;
          opacity: 0.15;
          z-index: -1;
        }
      }
      &.nw {
        color: white;
        @include striped-background-10px($local-red, $local-red-dark);
        z-index: 2;

        &::after {
          content: "ERR";
          position: absolute;
          top: 40%;
          right: 0;
          color: black;
          transform: translateY(-50%);
          font-size: 5em;
          font-weight: 900;
          opacity: 0.15;
          z-index: -1;
        }
      }
      &.w {
        color: white;
        @include striped-background-10px($local-green, $local-green-dark);
        z-index: 2;

        &::after {
          content: "WORKING";
          position: absolute;
          top: 40%;
          right: 0;
          color: black;
          transform: translateY(-50%);
          font-size: 5em;
          font-weight: 900;
          opacity: 0.15;
          z-index: -1;
        }
      }
    }
    .bookmarklet {
      display: block;
      word-wrap: break-word;
      word-break: break-all;
      text-wrap: wrap;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      &:nth-last-child(1) {
        border: none;
      }
      a {
        position: relative;
      }
    }
    .desc {
      margin-top: 10px;
      display: block;
      font-weight: 500;
      margin-bottom: 10px;
      @include remove-user-interaction-basic;
      pointer-events: none;
      cursor: default;
      background: rgb(50, 50, 50);
      padding: 10px 20px;
      border-radius: 10px;
    }
  }
}
