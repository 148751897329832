.page-section.ads {
  @include flexbox(center, center, column);
  background: rgb(35, 35, 35);
  padding: 5vh 0 10vh 0;
  margin-top: -50px;
  margin-bottom: 0;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    background: $primary;
    position: absolute;
  }

  .title {
    font-size: 2em;
    color: white;
    padding-bottom: 5vh;
    margin-bottom: 20px;
  }

  .ads {
    width: 100%;
    padding: 20px;
    position: relative;

    @media screen and (max-width: 500px) {
      padding: 0;
    }

    .items {
      display: grid;
      grid-template-columns: repeat(1fr);
      grid-gap: 20px;

      @media screen and (max-width: 500px) {
        grid-gap: 0;
      }

      .item {
        position: relative;
        width: 100%;
        height: 100px;
        border-radius: 15px;
        @include overflow-none;
        transition: 200ms ease all, outline 300ms ease;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        outline: 5px solid transparent;
        outline-offset: -2px;

        &:hover,
        &:focus,
        &:target {
          transform: translateY(-5px);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          outline: 5px solid $primary;
          outline-offset: -1px;

          @media screen and (max-width: 500px) {
            transform: translateY(0);
            outline: 5px solid transparent;
          }

          .text {
            color: $primary !important;
            font-size: 2em !important;
            background: rgba(35, 35, 35, 0.9) !important;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
          }

          img {
            filter: saturate(1);
          }
        }

        @media screen and (max-width: 500px) {
          border-radius: 0;
          height: 150px;
        }

        .text {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 20px;
          color: white;
          font-weight: 900;
          @include flexbox(center, flex-start, none);
          flex-wrap: wrap;
          font-size: 1.5em;
          text-align: left;
          transition: 300ms ease-in-out all;
          z-index: +3;
          -webkit-backdrop-filter: blur(0);
          backdrop-filter: blur(0);

          @media screen and (max-width: 550px) {
            text-align: center;
            justify-content: center;
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
          }
        }

        .img-overlay {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.7);
          position: absolute;
          z-index: +2;
        }

        img {
          filter: saturate(0.2);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease, 1s ease all;
          z-index: +1;
        }
      }
    }

    .more {
      @include flexbox(center, center, none);
      margin-top: 20px;
      width: 100%;
      a {
        text-align: center;
        width: 80%;
        max-width: 200px;
        background: rgb(55, 55, 55);
        padding: 10px 20px;
        border-radius: 5px;
        transition: 100ms ease all;
        &:hover {
          background: rgb(60, 60, 60);
        }
      }
    }
  }
}
