.page-container.downloads {
  @include flexbox(center, flex-start, column);
  width: 100%;
  padding: 0 20px;
  padding-bottom: 400px;
  position: relative;
  @import "title";
  .page-title {
    text-align: center;
    width: 100%;
    color: white;
    position: relative;
    font-weight: 500;
    font-size: 1.2em;
  }
  @import "filter";
  @import "ad-downloads";
}
