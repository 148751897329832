.text {
  &-color {
    &-red {
      color: red !important;
    }
    &-white {
      &-2 {
        color: rgb(250, 250, 250) !important;
      }
    }
  }
}
